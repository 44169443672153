@import url("https://fonts.googleapis.com/css?family=Sacramento&display=swap");
.introModule {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: "90%";
  max-width: 450px;
  min-width: 300px;
  /* height: 90vh; */
  background-color: var(--bgcard);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.introTimer {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.dTitle {
  margin-bottom: 15px;
}
.dText {
  text-align: left;
  font-size: 26px;
  color: white;
}
.dSpan {
  font-size: 26px;
  font-weight: bolder;
  color: var(--accent);
}
.ccArrowDiv {
  background: #4bc9ce; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #45b649,
    #4bc9ce
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #45b649,
    #4bc9ce
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: left;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  position: absolute;
  top: 85px;
  left: -10px;
  height: 60px;
  border-radius: 3px;
}
.ccArrowDiv::after {
  content: "";
  position: absolute;
  /* top: 100%; */
  right: -48.5px;
  width: 0;
  height: 0;
  border-top: solid 30px transparent;
  border-left: solid 50px #4bc9ce; /* fallback for old browsers */

  /* border-right: solid 50px blue; */
  border-bottom: solid 30px transparent;
}
.ccH5 {
  color: var(--bgcard);
  font-size: 21px;
}

.indiaIcon {
  scale: 3;
  margin: 0px 15px;
}
.introCard {
  border-radius: 15px;
  padding: 20px;
  /* margin-top: 20px; */
  text-align: center;
  margin-top: 100px;
  border: 0.1rem solid var(--black);
}
.introCard h6 {
  color: var(--white);
  margin-bottom: 30px;
}

.chipIcon {
  color: #242424;
  text-shadow: none;
  animation: detailsGlow 5s ease 3s infinite;
}

.dIcon2 {
  color: #242424;
  text-shadow: none;
  animation: detailsGlow 5s ease 2.5s infinite;
}
.text3 {
  color: #242424;
  text-shadow: none;
  animation: detailsGlow 5s ease 2s infinite;
}
.dIcon3 {
  color: #242424;
  text-shadow: none;
  animation: detailsGlow 5s ease 1.5s infinite;
}
.text4 {
  color: #242424;
  text-shadow: none;
  animation: detailsGlow 5s ease 1s infinite;
}
@keyframes detailsGlow {
  0% {
    text-shadow: 0 0px 10px var(--test1);
    color: #a8ff78;
    background: #a8ff78; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #78ffd6,
      #a8ff78
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #78ffd6,
      #a8ff78
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    display: inline;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  100% {
    color: #242424;
    text-shadow: none;
  }
}

.introTitle {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.blinkText {
  /* font-family: "Sacramento", cursive; */

  font-size: calc(28px);

  text-shadow: 0 0 5px var(--test1), 0 0 15px var(--test1),
    0 0 20px var(--test1), 0 0 40px var(--test1), 0 0 60px var(--pink),
    0 0 10px var(--test2), 0 0 98px var(--pink);
  color: var(--test1);
  text-align: center;
  animation: blink 10s infinite;
  -webkit-animation: blink 10s infinite;
}

.button {
  position: relative;
  border-radius: 5px;

  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.1rem solid var(--white);
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}
.button h3 {
  font-size: 1rem;
}

.button s {
  color: red;
}
.button:after,
.industryButton:after {
  content: "";
  background: var(--accent);
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}
.button:active:after,
.industryButton:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}
.glass {
  background: rgba(0, 255, 225, 0);
  backdrop-filter: blur(5.1px);
  -webkit-backdrop-filter: blur(5.1px);
  /* border: 1px solid rgba(0, 255, 225, 0.3); */
}
.glass:hover {
  background: rgba(49, 129, 132, 0.2);
  backdrop-filter: blur(5.1px);
  -webkit-backdrop-filter: blur(5.1px);
  /* border: 1px solid rgba(0, 255, 225, 0.3); */
}
.bmButton {
  margin-top: 15px !important;
  border: 3px solid transparent;
  border-image: linear-gradient(to right, #45b649, #4bc9ce);
  border-image-slice: 1;
}
.introIcon {
  scale: 2;
  color: var(--accent);
  animation: backdropIcon 5s ease-in-out infinite alternate;
}
.shine {
  position: relative;
  overflow: hidden;
}
.shine::before {
  content: "";
  position: absolute;
  top: 0;
  left: -80%;
  z-index: 2;
  width: 70%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, rgb(255, 49, 49) 100%);
  transform: skewX(-25deg);
  z-index: 1;
  animation: shine 5s infinite;
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes backdropIcon {
  0% {
    filter: drop-shadow(-10px -5px 1px var(--accent));
  }
  100% {
    filter: drop-shadow(20px 5px 10px var(--accent));
  }
}
@-webkit-keyframes blink {
  20%,
  24.5%,
  55% {
    color: #111;
    text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    /*     color: #fccaff;
    text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
      0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
    text-shadow: 0 0 5px var(--test1), 0 0 15px var(--test1),
      0 0 20px var(--test1), 0 0 40px var(--test1), 0 0 60px var(--pink),
      0 0 10px var(--test2), 0 0 98px var(--pink);
    color: #fff6a9;
  }
}
@keyframes blink {
  20%,
  24.5%,
  55% {
    color: #111;
    text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    /*     color: #fccaff;
    text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
      0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
    text-shadow: 0 0 5px var(--test1), 0 0 15px var(--test1),
      0 0 20px var(--test1), 0 0 40px var(--test1), 0 0 60px var(--pink),
      0 0 10px var(--test2), 0 0 98px var(--pink);
    color: #fff6a9;
  }
}

/* @keyframes backdropIcon2 {
  0% {
    filter: drop-shadow(-10px -5px 5px var(--accent));
  }
  100% {
    filter: drop-shadow(20px 5px 10px var(--accent));
  }
} */
/* @media (min-width: 600px) {
} */
@media (max-width: 600px) {
  .introTitle {
    margin-top: 10px;
  }
  .blinkText {
    font-size: calc(32px);
  }
  .introImg {
    height: 240px;
    width: 200px;
  }
  /* .ccArrowDiv {
    top: 140px;
  } */
}
@media (max-width: 1100px) {
  /* .dTitle {
    top: 20rem;
  } */
  .dText,
  .dSpan {
    font-size: 16px;
  }

  .ccH5 {
    font-size: 14px;
  }
  .text3,
  .text4 {
    font-size: 14px;
  }
  .ccArrowDiv {
    top: 130px;
  }
  /* .introCard {
    margin-top: 100px;
  } */
}
