@import "../../colorVariables.css";
.cs-fooer_main {
  padding: 35px 35px;
  display: flex;
  justify-content: space-between;
}

.cs-copyright {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
}
.footerP {
  text-align: left;
}
.cs-footer_item {
  margin-bottom: 40px;
  display: flex;
}
.cs-footer_item .cs-newsletter.cs-style1 {
  margin-top: 6px;
}

.cs-widget_title {
  font-size: 18px;
  margin-bottom: 35px;
}

.cs-menu_widget.cs-style1 {
  padding: 0;
  margin: 0;
  list-style: none;
}
.cs-menu_widget.cs-style1 li {
  display: flex;
}
.cs-menu_widget.cs-style1 li span {
  font-size: 24px;
  flex: none;
  width: 35px;
}
.cs-menu_widget.cs-style1 li:not(:last-child) {
  margin-bottom: 20px;
}

.cs-menu_widget.cs-style2 {
  display: flex;
  flex-wrap: wrap;
}
.cs-menu_widget.cs-style2 li:not(:last-child)::after {
  content: "|";
  margin: 0 10px;
  position: relative;
  top: -1px;
}
.footerLeft {
  display: flex;
  align-content: center;
}
.cs-text_widget p {
  margin: 0;
  color: white;
  /* width: 350px; */
}
/* .cs-text_widget img + p {
  margin-top: 30px;
} */

.cs-social_btns.cs-style1 {
  display: flex;
  flex-wrap: wrap;
  margin: -7px;
}
.cs-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cs-social_btns.cs-style1 a {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 7px;
  color: #fff;
}
.cs-social_btns.cs-style1 a:hover {
  border-color: var(--accent);
  transform: scale(1.08);
}
.cs-social_btns.cs-style1 a:hover svg {
  fill: var(--accent);
}

.cs-newsletter.cs-style1 .cs-newsletter_form {
  position: relative;
}
.cs-newsletter.cs-style1 .cs-newsletter_input {
  height: 50px;
  width: 100%;
  border-radius: 10px;
  background-color: #000;
  border: none;
  padding: 5px 90px 5px 15px;
  outline: none;
  color: #fff;
}
.cs-newsletter.cs-style1 .cs-newsletter_btn {
  padding: 7px 25px;
  border-radius: 10px;
  color: #fff;
  background-color: var(--accent);
  position: relative;
  top: 5px;
  right: 5px;
  font-weight: 600;
  border: none;
  overflow: hidden;
}
.cs-newsletter.cs-style1 .cs-newsletter_btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition-property: transform;
  transition-duration: 0.6s;
  transition-timing-function: ease-out;
  border-radius: inherit;
}
.cs-newsletter.cs-style1 .cs-newsletter_btn span {
  position: relative;
  z-index: 1;
}
.cs-newsletter.cs-style1 .cs-newsletter_btn:hover::before {
  transform: scaleX(1);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.cs-newsletter.cs-style1 .cs-newsletter_text {
  margin-top: 25px;
}

.cs-bottom_footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 1px solid var(--border);
}
.cs-bottom_footer > * {
  padding: 4px 0;
}

.cs-text_widget + .cs-social_btns.cs-style1 {
  margin-top: 25px;
}

@media screen and (max-width: 1199px) {
  .cs-newsletter.cs-style1 .cs-newsletter_btn {
    padding: 7px 15px;
  }
  .cs-newsletter.cs-style1 .cs-newsletter_input {
    padding: 5px 80px 5px 15px;
  }
}
@media screen and (max-width: 991px) {
  .cs-widget_title {
    margin-bottom: 20px;
  }
  .cs-text_widget img + p {
    margin-top: 20px;
  }
  .cs-menu_widget.cs-style1 li:not(:last-child) {
    margin-bottom: 10px;
  }
  .cs-text_widget + .cs-social_btns.cs-style1,
  .cs-newsletter.cs-style1 .cs-newsletter_text {
    margin-top: 15px;
  }
  .cs-fooer_main {
    /* padding: 60px 0 15px; */
  }
}
@media screen and (max-width: 767px) {
  .cs-fooer_main {
    padding: 5px;
  }
  .cs-fooer_main {
    flex-direction: column;
    gap: 10px;
  }
  .cs-text_widget {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
