@import "../../colorVariables.css";
.nav__container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.navBar .logo img {
  float: left;
  max-height: 120px;
}
.is-active {
  color: var(--accent) !important;
}

ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu > li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  display: block;
  color: var(--white);
  margin: 27px 17px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.7s;
}

.header__middle__menus {
  width: 70%;
  display: inline-block;
  /* float: left; */
}

.header__middle {
  display: flex;
  width: 100%;
  float: left;
  position: relative;
}

/* .active{
  color: #ff1800!important;
} */

.sub__menus__arrows {
  position: relative;
}
/* .sub__menus__arrows svg,
.sub__menus__arrows__full svg {
  padding-top: 0px;
  position: absolute;
  top: 13px;
} */
.sub__menus {
  position: absolute;
  display: none;
  background: rgb(32, 34, 34);
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.2);

  min-width: 280px;
  border-radius: 5px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  transition: all 0.8s;
}

@media (max-width: 767px) {
  .header__middle .active {
    color: var(--accent) !important;
  }
}

@media (min-width: 992px) {
  .sub__menus__arrows:hover .sub__menus {
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus {
    display: block;
    /* z-index: 1000000; */
  }
}
/* .menu-item a {
  filter: drop-shadow(-20px -5px 8px var(--accent));
} */
.menu-item .sub__menus a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a {
  filter: drop-shadow(-20px -5px 8px var(--accent));
}
.menu-item .sub__menus li:hover a {
  filter: drop-shadow(-20px -5px 8px var(--accent));
}

@media (max-width: 991px) {
  .header__middle__menus {
    width: 20%;
  }

  .main-nav .menubar__button {
    display: block !important;
    float: right;
    /* background-color: var(--black); */
    color: var(--white);
    padding: 3px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    z-index: 10037;
  }
  .main-nav .menubar__button svg {
    font-size: 27px;
  }
  .main-nav ul.menuq2 {
    display: block !important;
    position: absolute;
    top: 40px;
    left: -90px;
    z-index: 10007;
    background: rgb(32, 34, 34);
    box-shadow: 0 4px 30px rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    animation: move 0.4s 1 forwards;
    /* min-width: 280px; */
    /* padding-bottom: 17px;
    padding-top: 57px; */
  }
  .main-nav .menu-item a {
    position: relative;
    margin: 10px 17px;
    text-align: center;
    color: var(--white);
  }

  .main-nav ul.main-menu {
    display: none;
  }
  /* .main-nav .sub__menus__arrows:hover .sub__menus {
    margin: 0 auto;
    left: 0;
    right: 0;
  } */
  .sub__menus__Active {
    display: block !important;
    margin: 0 auto;
    top: 160px;
    left: -260px;
    width: 280px;
    right: 0;
    animation: move 0.4s 1 forwards;
  }
  .sub__menus__Active2 {
    display: block !important;
  }

  .menu-item .sub__menus__full a {
    text-align: left;
  }
  .headerRight {
    display: none;
  }
}

@keyframes move {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
  }
}

.sub__menus a::before,
.main-menu a::before {
  content: " ";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--accent);
  transition: 0.5s;
}
.sub__menus a:hover::before,
.main-menu a:hover::before {
  width: 100%;
}
