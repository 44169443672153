body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}
.container {
  width: 100%;
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.bold {
  font-weight: 500;
}
.d-flex {
  display: flex;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  color: var(--primary);
  padding: 0;
  font-weight: 600;
  line-height: 1.3em;
  font-family: "Poppins", sans-serif;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.cs-primary_font {
  font-family: "Poppins", sans-serif;
}

.cs-secondary_font {
  font-family: "Open Sans", sans-serif;
}
a {
  text-decoration: none !important;
}
h1 {
  font-size: 56px;
}

h2 {
  font-size: 42px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  margin-bottom: 15px;
}
