.headerMain {
  background-color: black;
  z-index: 100;
  position: fixed;
  width: 100vw;
  left: 0;
  transition: all 1s ease;
  padding: 0 20px;
}

.showHeader {
  position: fixed;
  top: 0;
  left: 0pt;
  height: 50px;
  transition: all 0.4s ease;
  z-index: 10;
}
.hideHeader {
  transform: translateY(-115%);
  -webkit-transform: translateY(-115%);
  -ms-transform: translateY(-115%);
}

.hideFooter {
  transform: translateY(112%);
  -webkit-transform: translateY(112%);
  -ms-transform: translateY(112%);
}
.stickyHeader {
  background: rgba(49, 129, 132, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.headerContainer {
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerCenter {
  color: wheat;
}
.headerRight {
  color: wheat;
}
