/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
:root {
  --accent: #2d8184;
  --accent1: rgba(--accent, 0.2);
  --dimaccent: rgba(var(var(--accent), 0.5));
  --white: #fff;
  --black: #161616;
  --primary: #fefefe;
  --secondary: rgba(--primary, 0.7);
  --ternary: #999696;
  --bgcard: #2b2929;
  --border: #4d4d4d;
  --gray: #181818;
  --dimaccent: rgba(--accent1, 0.5);
  --pink: rgba(163, 102, 159);
  --test1: #a1d6d8;
  --test2: #2d8184;
  --test3: #4bc9ce;
  --green: #a8ff78;
  --lightblue: #78ffd6;
}

/* // --text: #e6f0f0;
  // --accent: #091111;
  // --primary: #a1d6d8;
  // --secondary: #2d8184;
  // --accent: #4bc9ce;
  
  // :root {
  //   --accent: #ff4a17;
  // }
  // --white: #fff;
  // --black: #161616;
  // --primary: #fefefe;
  // --secondary: rgba(--primary, 0.7);
  // --ternary: #999696;
  // --border: #4d4d4d;
  // --gray: #181818;
  // --accent: var(--accent);
   */
