@import "../../colorVariables.css";
.wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  background-color: black;
  margin-top: 80px;
}
.quiz-container {
  width: 100%;
}
@media (min-width: 768px) {
  .wrapper {
    margin-top: 100px;
  }
  .quiz-container {
    width: 70%;
  }
}
.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 10px;
}
.video-iframe {
  margin: auto;
  width: 300px;
  height: 165px;
}
@media (min-width: 768px) {
  .wrapper {
    margin-top: 100px;
  }
  .video-iframe {
    width: 560px;
    height: 315px;
  }
  .quiz-container {
    width: 70%;
  }
}

.quiz-card {
  background-color: var(--bgcard);
  position: relative;
  border-radius: 0.75rem;
  background-size: cover;
  overflow: hidden;
  border-radius: 2rem;
  margin: 10px;
  height: auto;
  max-height: 52.5px;
  box-shadow: 0px 10px 30px -5px rgba(45, 129, 132, 0.3);
  transition: max-height 1s ease;
  color: white;
  margin-bottom: 90px;
}
.quiz-results-main {
  background-color: var(--bgcard);
  position: relative;
  border-radius: 0.75rem;
  background-size: cover;
  overflow: hidden;
  border-radius: 2rem;
  margin: 10px;
  padding: 5px;
  height: auto;
  color: white;
  margin-bottom: 90px;
}
.quiz-begin {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quiz-input {
  height: 30px;
  width: 80%;
  border-radius: 10px;
  background-color: var(--black);
  border: none;
  text-align: center;
  outline: none;
  color: var(--test3);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.tray-container {
  position: relative;
  border-radius: 40px;
  background-color: var(--bgcard);
  margin: 5px;
}
.tray-background {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 40px;
  height: 100%;
  transition: width 0.8s;
}
.quiz-tray {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 40px;
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.icon {
  background: var(--black);
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 10px 15px 10px;
  transition: 0.8s;
}
.question-main {
  visibility: hidden;
}
.quiz-head {
  position: absolute;
  background-color: var(--accent) !important;
  top: -20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.question-div {
  background-color: var(--bgcard);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 15px;
  padding-top: 60px;
  transition-delay: 0.3s;
  transition: all 0.3s ease;
  align-items: center;
}
.question-div p {
  padding-top: 5px;
  font-size: 14px;
}

.description h4 {
  text-transform: uppercase;
}

hr {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px;
}

.options-div {
  width: 90%;
  background: rgb(9, 17, 17);
  background: linear-gradient(
    90deg,
    rgba(9, 17, 17, 1) 48%,
    rgba(77, 77, 77, 1) 100%
  );
  border-radius: 5px;
  display: flex;
  justify-content: left;
  height: fit-content;
  margin-bottom: 15px;
  cursor: pointer;
}
.options-div:active {
  transition: all 0.5s ease;
}
.options-bullet {
  background-color: var(--accent);
  text-align: center;
  border-radius: 5px 0px 0px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.options h6 {
  padding: 5px;
  margin: 0px;
}
.back-arrow {
  margin: 0px 0px 15px 2.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.back-arrow:hover {
  color: var(--accent);
}

.quiz-button {
  position: relative;
  margin: 5px 5% 15px 0px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
  width: auto;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.1rem solid var(--white);
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}
.quiz-button:hover {
  color: var(--accent);
}

.quiz-button-begin {
  position: relative;

  margin: 15px 0px 15px 0px;

  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  width: "85px";
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.1rem solid var(--white);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.quiz-button-begin:hover {
  color: var(--accent);
}

.quote-button {
  position: relative;

  margin-top: 400px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
  width: auto;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.1rem solid var(--white);
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.quote-button:hover {
  color: var(--accent);
}
.quote-button2 {
  position: relative;

  margin-top: 400px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
  width: auto;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.1rem solid var(--white);
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.quote-button2:hover {
  color: var(--accent);
}

.score-button {
  position: relative;
  border-radius: 2px;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  color: white;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  color: black;
  border: 0.1rem solid var(--white);
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  background: rgb(255, 49, 49);
  background: linear-gradient(
    90deg,
    rgba(255, 49, 49, 1) 31%,
    rgba(255, 145, 77, 0.9668242296918768) 100%
  );
}

.score-button:hover {
  /* color: var(--accent); */
  background: rgb(255, 49, 49);

}

.options-hint {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  /* padding-bottom: 0px; */
  font-size: 13px;
}
@media (min-width: 1200px) {
  .options-hint {
    font-size: 16px;
  }
  .question-div p {
    font-size: 20px;
  }
}

.quiz-button:after,
.quiz-button-begin:after,
.score-button:after,
.quote-button:after,
.quote-button1:after {
  content: "";
  background: var(--accent);
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}
.quiz-button:active:after,
.quiz-button-begin:active:after,
.score-button:active:after,
.quote-button:active:after,
.quote-button1:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}
.quiz-icon {
  scale: 1.5;
}

.selected {
  height: auto;
  max-height: 1000px;
}
.selected .question-main {
  visibility: visible;
}
.selected .icon {
  margin: 15px;
  transition: margin 0.3s ease;
}
.selected .quiz-head {
  top: 0;
}

.selected .question-div {
  background-color: var(--bgcard);
}
.blank-span {
  height: 20px;
}
.quote-top {
  margin-top: 30px;
}
.quote-main {
  /* background-color: var(--gray); */
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.quoteModule {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* width: "90%"; */
  /* max-width: 450px; */
  width: 100%;
  height: 100vh;
  /* background-color: var(--bgcard); */
  /* border-radius: 5px; */
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
  overflow: scroll;
}
.scorecard-container {
  display: grid;
  gap: 25px;
}
@media (min-height: 840px) and (max-width: 600px) {
  .quoteModule {
    /* background-color: red; */
    margin-top: 120px;
  }
}
@media (min-width: 900px) {
  .scorecard-container {
    grid-template-columns: 1fr;
  }

  /* .scorecard-container > :first-child {
    grid-column: span 2;
  } */
}

@media (min-width: 1200px) {
  .scorecard-container {
    grid-template-columns: 1fr 1fr;
  }

  .scorecard-container > :first-child {
    grid-column: span 2;

    /* background-color: red; */
  }
}
.score-card {
  position: relative;
  background-color: var(--gray);
  max-width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 0.75rem;
  padding: 10px;
  margin: auto;
  margin-top: 20px;
  flex: 50%;

  /* padding: 25px; */
}
/* .score-card {
  border: 1px solid transparent;
  border-image: linear-gradient(to right, lightgreen, var(--accent));
  border-image-slice: 1;
}
.scorecard-container > :first-child {
  border: 5px solid transparent;
  border-image: linear-gradient(to right, lightgreen, var(--accent));
  border-image-slice: 1;
} */

.scoreh5 {
  font-size: 14px;
}
.scoreSpan {
  font-size: 18px;
  font-weight: bold;

  color: lightgreen;
}
.score {
  background-color: var(--border);
  margin-bottom: 15px;
  color: var(--white);
  border-radius: 0.3rem;
}

.score2 {
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 15px;
  width: auto;
  background-color: white;
}
.score2 h6 {
  white-space: nowrap;
}
.ad {
  position: relative;
}
.scArrowDiv {
  background: #4bc9ce; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #45b649,
    #4bc9ce
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #45b649,
    #4bc9ce
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: left;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  position: absolute;
  /* top: 45px; */
  left: -10px;
  height: 50px;
  border-radius: 3px 3px 3px 3px;
  min-width: 120px;
}

.scArrowDiv::after {
  content: "";
  position: absolute;
  /* top: 100%; */
  right: -44px;
  width: 0;
  height: 0;
  border-top: solid 25px transparent;
  border-left: solid 45px #4bc9ce; /* fallback for old browsers */

  /* border-right: solid 50px blue; */
  border-bottom: solid 25px transparent;
}
.scH1,
.scH5 {
  color: black;
}
.scH5 {
  font-size: 21px !important;
  font-weight: bold !important;
}
.tri-right:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 5px;
  bottom: -5px;
  border: 4px solid;
  border-color: white white transparent transparent;
}
.result-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5px;
  h3,
  h4,
  h5,
  h6 {
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    margin: 5px !important;
  }
}
.ccRoadmapTitle {
  width: fit-content;
  padding: 10px 0px;
  margin: auto;
  margin-bottom: 10px;
  border: 3px solid transparent;
  border-image: linear-gradient(to right, #45b649, #4bc9ce);
  border-image-slice: 1;
}
.ccRoadmapTitle h5 {
  font-size: 22px;
  padding: 0px 10px;
}
.scContent {
  /* margin-top: 90px; */
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.scContent1 {
  margin-top: 60px;
}
.ccLogo {
  display: flex;
  justify-content: center;
}
.ccLogo img {
  width: 80px;
}
.ccList ul {
  margin: 25px 0;
}
.ccList {
  text-align: left;
}
.ccList li {
  margin-top: 15px;
  list-style-type: disc;
  list-style-position: outside;
  font-size: 12px;
  line-height: 1.3em;
  color: white;
}
.quote-img {
  position: relative;
}
.img_01 {
  position: absolute;
  top: 75px;
  left: 50%;
  animation: semi-rotate-anim1 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  rotate: (-4deg);
  cursor: pointer !important;
}
.img_01_01 {
  position: absolute;
  top: -130px;
  left: 0%;
  animation: semi-rotate-anim1 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  rotate: (-4deg);
  cursor: pointer !important;
}
.img_02 {
  position: absolute;
  top: 125px;
  left: 20%;
  animation: semi-rotate-anim2 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  rotate: (2deg);
}
.img_03 {
  position: absolute;
  top: 250px;
  left: 30%;
  animation: semi-rotate-anim3 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  rotate: (4deg);
}
.img_04 {
  position: absolute;
  top: 15x;
  left: 10%;
  animation: semi-rotate-anim4 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  rotate: (4deg);
}
.img_05 {
  position: absolute;
  top: 140px;
  right: 10%;
  animation: semi-rotate-anim5 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  rotate: (8deg);
}
.img_06 {
  position: absolute;
  top: 250px;
  left: 23%;
  animation: semi-rotate-anim3 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  rotate: (8deg);
}
.img_06_01 {
  position: absolute;
  top: 300px;
  left: 30%;
  animation: semi-rotate-anim3 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  rotate: (8deg);
}
.img_07 {
  position: absolute;
  top: 70px;
  right: 50%;
  animation: semi-rotate-anim2 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  rotate: (8deg);
}
.img_08 {
  position: absolute;
  top: 90px;
  right: 33%;
  animation: semi-rotate-anim1 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  rotate: (8deg);
  @media (min-width: 768px) {
    right: 43%;
  }
}
.img_09 {
  position: absolute;
  top: 75px;
  right: 7%;
  animation: semi-rotate-anim3 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  rotate: (8deg);
  @media (min-width: 768px) {
    right: 10%;
  }
}
.quoteClose {
  color: white;
  scale: 2;
  position: absolute;
  right: 0px;
  top: 30px;
  margin: 20px;
  cursor: pointer;
}
.img_10 {
  position: absolute;
  top: 280px;
  left: 30%;
  animation: semi-rotate-anim4 12s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  rotate: (2deg);
}
@media (min-width: 1100px) {
  .ccRoadmapTitle h5 {
    font-size: 22px;
  }
  .ccList li {
    font-size: 16px;
    line-height: 1.3em;
  }
  .scArrowDiv {
    left: -30px;
  }
  .score-card {
    max-width: 350px;
    min-height: 620px;
  }
  .img_01 {
    top: 75px;
    left: 50%;
    height: 150px !important;
    width: 180px !important;
  }
  /* .img_01_01 {
    background-color: red;
    top: -120px;
    left: -5%;
    height: 150px !important;
    width: 180px !important;
  } */
  .img_02 {
    top: 125px;
    left: 0%;
    height: 180px !important;
    width: 160px !important;
  }
  .img_03 {
    top: 300px;
    left: 30%;
    height: 220px !important;
    width: 220px !important;
  }
  .img_04 {
    top: 15x;
    left: 10%;
    height: 250px !important;
    width: 400px !important;
  }
  .img_05 {
    top: 200px;
    right: 20%;
    height: 160px !important;
    width: 180px !important;
  }
  .img_06,
  .img_06_01 {
    top: 350px;
    left: 10%;
    height: 250px !important;
    width: 250px !important;
  }

  .img_07 {
    right: 60%;
    top: 70px;
    right: 50%;
    height: 240px !important;
    width: 250px !important;
  }
  .img_08 {
    right: 43%;
  }
  .img_09 {
    top: 100px;
    right: 20%;
    height: 180px !important;
    width: 140px !important;
  }
  .img_10 {
    top: 380px;
    left: 40%;
    height: 200px !important;
    width: 280px !important;
  }
  .quote-button {
    margin-top: 550px;
  }
  .quote-button2 {
    margin-top: 600px;
  }
  .quoteClose {
    scale: 2;
    color: white;
    position: absolute;
    right: 0px;
    margin: 20px;
    cursor: pointer;
  }
}

.test {
  z-index: 1310 !important;
}
@media screen and (max-width: 990px) {
  h6 {
    font-size: 12px;
  }
}
@keyframes semi-rotate-anim1 {
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(-25px) rotate(4deg);
  }
}

@keyframes semi-rotate-anim2 {
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(25px) rotate(-4deg);
  }
}

@keyframes semi-rotate-anim3 {
  50% {
    animation-timing-function: cubic-bezier(0, 0.2, 0.6, 1);
    transform: rotate(-8deg);
  }
}

@keyframes semi-rotate-anim4 {
  50% {
    animation-timing-function: cubic-bezier(0, 0.2, 0.6, 1);
    transform: translateX(-50px) rotate(-4deg);
  }
}
@keyframes semi-rotate-anim5 {
  50% {
    animation-timing-function: cubic-bezier(0, 0.2, 0.6, 1);
    transform: translateX(25px) rotate(-8deg);
  }
}

@keyframes animate {
  0%,
  100% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(360deg);
  }
}

@keyframes animateGradient {
  0% {
    background-position: 0% center;
  }
  50% {
    background-position: 30% center;
  }
  100% {
    background-position: 0% center;
  }
}

.box {
  /* width: 60vmin;
  height: 50vmin;
  display: grid; */
  /* place-content: center;
  color: white; */
  /* text-shadow: 0 1px 0 #000; */

  --border-angle: 0turn; /* For animation */
  --main-bg: conic-gradient(
    from var(--border-angle),
    /* #213,
    #112 5%,
    #112 60%,
    #213 95% */ var(--black),
    var(--black),
    var(--black),
    #1f1e1e 95%
  );

  border: solid 5px transparent;
  border-radius: 1px;
  --gradient-border: conic-gradient(
    from var(--border-angle),
    transparent 25%,
    var(--test1),
    var(--pink),
    var(--accent) 99%,

    /* #08f,
    #f03 99%, */ transparent
  );

  background: var(--main-bg) padding-box, var(--gradient-border) border-box,
    border-box;

  background-position: center center;
  animation: bg-spin 3s linear infinite;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

.box:hover {
  animation-play-state: paused;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
